export const serviceOptions = [
  { title: 'Academic Underachievement' },
  { title: 'ADHD (Therapy)' },
  { title: 'ADHD (Coaching)' },
  { title: 'Addiction' },
  { title: 'Adjustments' },
  { title: 'Adoption' },
  { title: 'Alcohol Abuse' },
  { title: "Alzheimer's" },
  { title: 'Anger Management' },
  { title: 'Animal Support - Writing Certification Letter' },
  { title: 'Antisocial Personality' },
  { title: 'Anxiety or Fears' },
  { title: 'Aspergers Syndrome' },
  { title: 'Autism' },
  { title: 'Behavioral Issues' },
  { title: 'Bipolar Disorder' },
  { title: 'Body Dysmorphic Disorder' },
  { title: 'Borderline Personality' },
  { title: 'Bullying (being bullied)' },
  { title: 'Bullying (being the bully)' },
  { title: 'Career Counseling' },
  { title: 'Caregiver Stress' },
  { title: 'Child or Adolescent' },
  { title: 'Chronic Impulsivity' },
  { title: 'Chronic Pain or Illness' },
  { title: 'Chronic Relapse' },
  { title: 'Coping Skills' },
  { title: 'Depression' },
  { title: 'Developmental Disorders' },
  { title: 'Dissociative Identity (DID)' },
  { title: 'Divorce' },
  { title: 'Domestic Abuse' },
  { title: 'Domestic Violence (Current)' },
  { title: 'Domestic Violence (Past)' },
  { title: 'Drug Abuse (as primary issue)' },
  { title: 'Dual Diagnosis' },
  {
    title:
      'Eating Disorder: Anorexia/Bulimia (Mild-Moderate), as #1 Therapy Issue',
  },
  {
    title:
      'Eating Disorder: Anorexia/Bulimia (Moderate-Severe), as #1 Therapy Issue',
  },
  { title: 'Eating Disorder: Anorexia/Bulimia, as #2 Therapy Issue' },
  { title: 'Eating Disorder: Binge Eating Disorder' },
  { title: 'Entitlement' },
  { title: 'Family Conflict' },
  { title: 'Gambling' },
  { title: 'Hoarding' },
  { title: 'Incest - Perpetrator' },
  { title: 'Incest - Victim' },
  { title: 'Infidelity - Perpetrator' },
  { title: 'Infidelity - Victim' },
  { title: 'Infertility' },
  { title: 'Internet Addiction' },
  { title: 'Learning Disability' },
  { title: 'LGBTQIAA+ (issues; identity development, coping with oppression)' },
  { title: 'Loss or Grief' },
  { title: 'Medical Detox' },
  { title: 'Mental Retardation (Treatment)' },
  { title: 'Mens Issues' },
  { title: 'Narcissistic Personality Disorder' },
  { title: 'Obesity (Presenting Issue)' },
  { title: 'OCD' },
  { title: 'Oppositional Defiance' },
  { title: 'Parenting' },
  { title: 'Peer Relationships' },
  { title: 'Post Partum Depression' },
  { title: 'Pregnancy / Prenatal' },
  { title: 'Racial Identity' },
  { title: 'Relationship Issues (General)' },
  { title: 'Relationships - Open Relationships' },
  { title: 'School Issues' },
  { title: 'Self Esteem' },
  { title: 'Sex Therapy' },
  { title: 'Sexual Abuse (Presenting Issue)' },
  { title: 'Sexual Abuse in Military (MST)' },
  { title: 'Sexual Addiction' },
  { title: 'Sexuality - BDSM' },
  { title: 'Sleep or Insomnia' },
  { title: 'Sociopathy' },
  { title: 'Substance Abuse' },
  { title: 'Teen Violence' },
  { title: 'Transgender/Sex Surgery - Writing Letter of Recommendation' },
  { title: 'Trauma and PTSD - Childhood' },
  { title: 'Trauma and PTSD - General' },
  { title: 'Traumatic Brain Injury' },
  { title: 'Trichotillomania' },
  { title: 'Video Game Addiction' },
  { title: 'Weight Loss' },
  { title: 'Womens Issues' },
];

export const psychologicalTestingOptions = [
  { title: '504 accommodations' },
  { title: 'ADHD Adult Diagnostic Evaluation' },
  { title: 'Anxiety' },
  { title: 'Autism Spectrum Disorder - Adult Evaluation' },
  { title: 'Bariatric Surgical Psychological Evaluations' },
  { title: 'Child Comprehensive Autism & ADHD Evaluation' },
  { title: 'Child Comprehensive PsychoEducational Evaluation' },
  { title: 'Child Developmental Autism Evaluation' },
  { title: 'Cosmetic Surgery Evaluations' },
  { title: 'Depression' },
  { title: 'Developmental delays and early intervention' },
  { title: 'Differential Adult Diagnosis Evaluation' },
  { title: 'Evaluation for Accommodations on High Stakes Testing' },
  { title: 'Giftedness' },
  { title: 'Intellectual disabilities' },
  { title: 'Kindergarten readiness and early entry into kindergarten' },
  { title: 'Neuropsychological Testing' },
  { title: 'Nonverbal learning disabilities' },
  { title: 'Oppositional defiance and conduct disorders' },
  { title: 'Reading' },
  { title: 'Writing' },
  { title: 'Math Disability' },
  { title: 'Special education services' },
  { title: 'Spinal Stimulator Pre Surgery Evaluations' },
  { title: 'Transgender Evaluations' },
  { title: 'Transplant / Bone Marrow Transplant Pre-Surgical' },
  { title: 'Weaknesses in executive functioning' },
  { title: 'Weaknesses in memory and learning' },
];

export const forensicTestingOptions = [
  { title: 'Adoption Evaluations' },
  { title: 'Adult Competency' },
  { title: 'Child Abuse' },
  { title: 'Child Custody Evaluations' },
  { title: 'Criminal Mitigation' },
  { title: 'Criminal Responsibility' },
  { title: 'Expert Witness Testimony and Evaluations' },
  { title: 'Fitness for Duty Evaluation' },
  { title: 'Immigration Evaluation: Disability Waiver' },
  { title: 'Immigration Evaluation: Asylum Cases' },
  { title: 'Immigration Evaluation: Extreme or Exceptional Hardship' },
  { title: 'Immigration Evaluation: Violence Against Women Act' },
  { title: 'Juvenile Competency' },
  { title: 'Law Enforcement/Weapon Screening' },
  { title: 'Medical Records Review' },
  { title: 'Parenting Capacity Evaluations' },
  { title: 'Risk Assessments- Violence/Sex' },
  { title: 'Sentencing Evaluations' },
  { title: 'Sex Crimes' },
  { title: 'White Collar Crime' },
];

export const therapyModalities = [
  { title: 'Acceptance and Commitment Therapy (ACT)' },
  { title: 'ACT Informed Therapy' },
  { title: 'Adlerian' },
  { title: 'Applied Behavioral Analysis' },
  { title: 'Art Therapy' },
  { title: 'Attachment-based' },
  { title: 'Christian Counseling' },
  { title: 'Coaching' },
  { title: 'Cognitive Behavioral Therapy (CBT)' },
  { title: 'DBT' },
  { title: 'EMDR' },
  { title: 'Eclectic' },
  { title: 'Emotionally Focused Therapy (EFT)' },
  { title: 'Existential' },
  { title: 'Experiential Therapy' },
  { title: 'Expressive Arts' },
  { title: 'Exposure and Response Prevention (ERP)' },
  { title: 'Feminist' },
  { title: 'Gestalt' },
  { title: 'Gottman Method' },
  { title: 'Humanistic' },
  { title: 'Hypnotherapy' },
  { title: 'Imago' },
  { title: 'Integrative' },
  { title: 'Internal Family Systems (IFS)' },
  { title: 'Interpersonal' },
  { title: 'Jungian' },
  { title: 'Mindfulness-based (MBCT)' },
  { title: 'Motivational Interviewing' },
  { title: 'Narrative' },
  { title: 'Neuro-Linguistic' },
  { title: 'Neurofeedback' },
  { title: 'Parent-Child Interaction Therapy (PCIT)' },
  { title: 'Person-Centered' },
  { title: 'Play Therapy' },
  { title: 'Positive Psychology' },
  { title: 'Prolonged Exposure Therapy' },
  { title: 'Psychoanalytic' },
  { title: 'Psychodynamic' },
  { title: 'Rational Emotive Behavior Therapy (REBT)' },
  { title: 'Relational' },
  { title: 'Sand Play' },
  { title: 'Solution Focused Brief (SFBT)' },
  { title: 'Somatic' },
  { title: 'Structural Family Therapy' },
];

export const therapySpecialties = [
  { title: 'Life Coaching' },
  { title: 'DBT Therapy - Primarily Use DBT in Therapy' },
  { title: 'DBT Therapy - Occasionally use DBT Skills when appropriate' },
  { title: 'Feminist Therapy' },
  { title: 'Premarital Counseling' },
];

export const specialConsiderations = [
  { title: 'Deaf or Hard of Hearing' },
  { title: 'Working with Mental Health Professionals' },
  { title: 'Working with Orthodox Jews' },
];

export const hospitalSurgeonMapping = {
  GBMC: [
    'Dr. Elizabeth Dovac',
    'Dr. Timothy Friesen',
    'Dr. Gustavo Bello Vincentelli',
  ],
  'George Washington University Hospital': [
    'Dr. Joseph Afram',
    'Dr. Hope Jackson',
    'Dr Juliet Lee',
    'Dr. Paul Lin',
    'Dr. Khashayar Vaziri',
  ],
  'Holy Cross Health': [
    'Dr. Tuesday Cooke',
    'Dr. Barry Greene',
    'Dr. Joseph Greene',
  ],
  'Johns Hopkins Bayview Medical Center': [
    'Dr. Gina Adrales',
    'Dr. Alisa Coker',
    'Dr. Thomas Magnuson',
    'Dr. Hein Tan Nguyen',
    'Dr. Michael Schweitzer',
  ],
  'MedStar Franklin Square': ['Dr. Alain Abdo', 'Dr. Christopher You'],
  'MedStar Montgomery County': ['Dr. John Brebbia', 'Dr. Fred Finelli'],
  'Mercy Hospital': ['Dr. Kudleep Singh'],
  'St. Agnes': [
    'Dr. Andrew Averbach',
    'Dr. Isam Hamdallah',
    'Dr. Catriona Swift',
  ],
  'University of MD Medical Center': [
    'Dr. Mark Kligman',
    'Dr. Natalia Kubicki',
  ],
};
